import { User } from '@core/models/user';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Firestore, FirestoreDataConverter } from '@angular/fire/firestore';
import { Company } from '../models/company';
import { FirestoreService } from './firestore.abstract.service';
import { UserService } from './user.service';
import { Observable, catchError, of, switchMap } from 'rxjs';
import { ErrorHandlerService } from '@core/services/error-handler.service';
import { TenableChildAccount } from '@core/tenable/models/tenable-child-account';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends FirestoreService<Company> {
  protected basePath: string = 'company';
  public company?: Company;
  public user?: User;

  constructor(
    fireStore: Firestore,
    public router: Router,
    public ngZone: NgZone,
    private userService: UserService,
    private errorHandler: ErrorHandlerService
  ) {
    super(fireStore); // Calling parent class (FirestoreService) constructor with Firestore as argument
  }

  // This is the code to see if the user has a company
  checkIfUserHasCompany() {

    this.getCurrentUserCompany();
    if (this.company) {

      return true;
    } else {
      return false;
    }
  }

  async setUserProfile() {

    this.userService.getUserModel().subscribe(async (user: User | null) => {
      if (user) {

        this.user = user;
        this.getCompany(this.user.companyId).then((company: Company | null) => {
            if (company) {
              this.company = company;
            }
          }).catch(error => {
            console.error("Error fetching company information: ", error);
          });
      }
    });
  }


  /**
     * Fetches and returns the company associated with the current user, also setting the internal `company` property.
     * This method only proceeds if `user` is defined, ensuring that there is a valid user context.
     *
     * @returns An Observable of the company details if the user is defined; otherwise, an empty Observable.
     */
  getCurrentUserCompany(): Observable<any> {
    return new Observable(subscriber => {
      if (!this.user) {
        this.setUserProfile();
      }
      if (this.user) {
        this.getCompany(this.user.companyId)
          .then((company: Company | null) => { // Update the type of the parameter to accept Company | null
            subscriber.next(company);
            subscriber.complete();
          })
          .catch(error => {
            console.error("Error fetching company information: ", error);
            subscriber.error(error);
          });
      } else {
        subscriber.next(null); // Return null if no user is found
        subscriber.complete();
      }
    });
  }

  getCompany(company_id: string): Promise<Company | null> {
    if(!company_id) {
      // throw error and fail
      this.errorHandler.handleError(new Error('Company ID is missing.'));
      return Promise.resolve(null);
    }
    return this.getDocument(company_id).then(
      (company: Company | null) => {
        return company;
      }
    );
  }
  /**
   * Retrieves the domain of the company associated with the current user.
   * This method leverages `getCurrentUserCompany` to fetch the company details
   * and then maps the result to extract and return the company domain.
   *
   * @returns An Observable of the company domain or `null` if no company is found or an error occurs.
   */
  getCompanySlug(): Observable<string | null> {
    if (this.company) {

      if (!this.company.domain && this.user) {

        this.company = this.updateDomainFromOwnerEmail(this.company, this.user);
        this.update(this.company, this.company.uid).then(() => { });
        return of(this.extractRootDomain(this.company.domain));
      }
      if (this.company.domain) {

        return of(this.extractRootDomain(this.company.domain));
      }
    }

    return this.getCurrentUserCompany().pipe(
      switchMap((company: Company) => {
        if (this.company) {

          return of(this.extractRootDomain(this.company.domain));
        }
        if (company) {

          return of(this.extractRootDomain(company.domain));  // Assuming `domain` is a property of `Company`
        }
        return of(null); // Return null if no company data is available
      }),
      catchError((err) => {
        console.error('Error fetching company:', err);
        return of(null); // Return null in case of an error
      })
    );
  }

  /**
  * Extracts the root part of the domain from a given domain string.
  * For example:
  * - 'sub.labworkz.com' -> 'labworkz'
  * - 'labworkz.com' -> 'labworkz'
  * - 'thisapp.co' -> 'thisapp'
  *
  * @param domain The full domain string.
  * @returns The root part of the domain.
  */
  private extractRootDomain(domain: string): string {
    const parts = domain.split('.');
    const length = parts.length;

    if (length >= 2) {

      return parts[length - 2];
    }
    return domain;
  }


  /**
 * Extracts the domain from the owner's email address and assigns it to the company's domain.
 *
 * @param company A Company instance to update the domain for.
 * @returns The updated Company instance with the domain derived from the owner's email.
 */
  updateDomainFromOwnerEmail(company: Company, user: User): Company {
    if (!company.ownerId) {

      throw new Error('Owner or owner email is missing in the company model.');
    }

    // Extract the domain from the owner's email.
    const emailDomain = user.email.split('@')[1];
    if (!emailDomain) {

      throw new Error('Invalid email format in owner email.');
    }

    // Assign the extracted domain to the company's domain property.
    company.domain = emailDomain;

    return company;
  }

  /**
   * Updates the account UUID of the company with the provided account details.
   *
   * @param accountDetails The account details to update the company with.
   */
  updateCompanyAccountDetails(accountDetails: TenableChildAccount) {
    if(this.company && accountDetails) {
      const containerCompanyName = environment.production ? this.company.uid : this.company.uid + '-dev';
      if (accountDetails.container_name != containerCompanyName) {
        console.error(' Admin Layout: An error occurred: Account details do not match company domain. Logging out..');
      } else {
        this.company.accountUuid = accountDetails.uuid;
        this.update(this.company, this.company.uid).then(() => { });
      }
    }
  }

  override getConverter(): FirestoreDataConverter<any> {
    return {
      // Convert User object to Firestore-compatible format
      toFirestore: (company: any) => {
        let companyData: Company = {
          uid: company.uid,
          accountUuid: company.accountUuid,
          name: company.name,
          domain: company.domain,
          description: company.description,
          image_url: company.image_url,
          createdAt: company.createdAt ? company.createdAt : new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          fileUpload: company.fileUpload,
          headquarters: company.headquarters,
          locations: company.locations,
          ownerId: company.ownerId,
        };
        companyData = this.removeEmpty(companyData); // Remove any empty/null values
        return companyData;
      },
      // Convert Firestore snapshot to User object
      fromFirestore: (snapshot: any, options: any) => {
        const data = snapshot.data(options);
        return new Company(data);
      },
    };
  }
}
