import { uuidv4 } from "@firebase/util";
import { startAfter, limit, collection, deleteDoc, doc, Firestore, FirestoreDataConverter, getDoc, getDocs, query, setDoc, orderBy } from "@angular/fire/firestore";
import { Inject, Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { where } from "firebase/firestore";

@Injectable({
  providedIn: "root"
})
export abstract class FirestoreService<T> {

  protected abstract basePath: string;
  debug: boolean = environment.debug;

  constructor(@Inject(Firestore) protected fireStore: Firestore) {
  }


  /**
   * Retrieves a collection from Firestore based on the provided parameters.
   *
   * @param queryFn Optional. A function that returns a query. This function can be used to filter the documents in the collection.
   *                Example usage: const customQueryFn = (collectionRef: any) => query(collectionRef, where("companyId", "==", companyId), where("email", "==", email), limit(1));
   *                this.getCollection$(customQueryFn);
   *                Default is no query.
   * @param orderByName Optional. The name of the field by which the collection should be ordered. If provided, documents will be ordered by this field. Default is no ordering.
   * @param pageSize The maximum number of documents to return per page. Default is 10.
   * @param startAfterDoc Optional. A document snapshot representing the document to start after. If provided, pagination will start after this document. Default is no specific document to start after.
   * @returns A promise that resolves to the query snapshot containing the documents.
   */
  async getCollection$(
    queryFn?: ((firestoreQuery: any) => any) | null,
    company_uid?: string | null,
    orderByName?: string | null,
    pageSize: number = 100,
    startAfterDoc?: any,
    orderByDirection: "asc" | "desc" = "asc"
  ): Promise<any> {
    try {
      // Get a reference to the Firestore collection
      const collectionRef = collection(this.fireStore, `${this.basePath}`);
      let q;

      // Construct the query based on the provided parameters
      if (queryFn) {
        // If a custom query function is provided, apply it to the collection reference
        q = queryFn(collectionRef);
      } else if (company_uid) {
        // Construct query based on company_uid and other parameters
        if (orderByName && startAfterDoc) {
          // Pagination with ordering and starting after a specific document
          q = query(
            collectionRef,
            where("companyUid", "==", company_uid),
            orderBy(orderByName, orderByDirection),
            startAfter(startAfterDoc),
            limit(pageSize)
          );
        } else if (orderByName) {
          // Ordering without pagination
          q = query(
            collectionRef,
            where("companyUid", "==", company_uid),
            orderBy(orderByName, orderByDirection),
            limit(pageSize)
          );
        } else if (startAfterDoc) {
          // Pagination without ordering
          q = query(
            collectionRef,
            where("companyUid", "==", company_uid),
            startAfter(startAfterDoc),
            limit(pageSize)
          );
        } else {
          // Default query without ordering or pagination
          q = query(
            collectionRef,
            where("companyUid", "==", company_uid),
            limit(pageSize)
          );
        }
      } else {
        // Construct query without company_uid
        if (orderByName && startAfterDoc) {
          // Pagination with ordering
          q = query(
            collectionRef,
            orderBy(orderByName, orderByDirection),
            startAfter(startAfterDoc),
            limit(pageSize)
          );
        } else if (orderByName) {
          // Ordering without pagination
          q = query(
            collectionRef,
            orderBy(orderByName, orderByDirection),
            limit(pageSize)
          );
        } else if (startAfterDoc) {
          // Pagination without ordering
          q = query(
            collectionRef,
            startAfter(startAfterDoc),
            limit(pageSize)
          );
        } else {
          // Default query without ordering or pagination
          q = query(
            collectionRef,
            limit(pageSize)
          );
        }
      }

      // Execute the query and return the results
      return await getDocs(q);
    } catch (error) {
      console.error("Error fetching collection: ", error);
      throw new Error("Failed to fetch collection");
    }
  }

  /**
   * Retrieves a document from Firestore based on the provided document ID.
   *
   * @param id The ID of the document to retrieve.
   * @returns A promise that resolves to the data of the retrieved document if it exists, or `false` if the document does not exist.
   */
  async getDocument(id: string): Promise<T | any | false> {
    // Get a reference to the Firestore document
    const docRef = doc(this.fireStore, `${this.basePath}`, `${id}`);

    // Retrieve the document snapshot
    const docSnap = await getDoc(docRef);

    // Check if the document exists
    if (docSnap.exists()) {
      // Return the document data
      return docSnap.data();
    } else {
      // Return false as the document does not exist
      return false;
    }
  }


  /**
   * Creates a new document in the Firestore collection with the provided value.
   *
   * @param value The data to be stored in the new document.
   * @param uid Optional. The unique identifier for the new document. If not provided, a new UUID will be generated and assigned to the document.
   * @returns A promise that resolves to the data of the newly created document, or an error if the creation process fails.
   */
  async create(value: any, uid?: string): Promise<T | any | Error> {
    // Generate a new UUID if uid is not provided
    if (!uid) {
      uid = uuidv4();
      value.uid = uid;
    }

    try {
      // Get the data converter
      const dataConverter = this.getConverter();

      // Get a reference to the Firestore collection
      const docRef = collection(this.fireStore, `${this.basePath}`);

      // Create a reference to the new document with the provided UID
      const ref = doc(docRef, uid).withConverter(dataConverter);

      // Set the data in the document
      await setDoc(ref, value);

      // Return the data of the newly created document
      return await this.getDocument(uid);
    } catch (error) {
      // Log and return the error if the creation process fails
      console.error(`Unsuccessful. Returned error: ${error}`);
      return error;
    }
  }


  /**
 * Updates an existing document in the Firestore collection with the provided value.
 *
 * @param value The updated data to be stored in the document.
 * @param uid The unique identifier of the document to be updated.
 * @returns A promise that resolves to the updated data of the document after the update operation completes successfully, or an error if the update process fails.
 */
  async update(value: any, uid: string): Promise<T | any | Error> {
    try {
      // Get the data converter
      const dataConverter = this.getConverter();

      // Get a reference to the Firestore collection
      const docRef = collection(this.fireStore, `${this.basePath}`);

      // Create a reference to the document to be updated
      const ref = doc(docRef, uid).withConverter(dataConverter);

      // Update the data in the document
      await setDoc(ref, value);

      // Return the updated data of the document
      return await this.getDocument(uid);
    } catch (error) {
      // Log and return the error if the update process fails
      console.error(`Unsuccessful. Returned error: ${error}`);
      return error;
    }
  }


  /**
   * Deletes a document from the Firestore collection.
   *
   * @param id The ID of the document to be deleted.
   */
  delete(id: string): void {
    // Get a reference to the document to be deleted
    const docRef = doc(this.fireStore, `${this.basePath}`, id);

    // Delete the document
    deleteDoc(docRef).then(() => { });
  }



  /**
   *
   * @returns CollectionReference
   */
  private get collection() {
    return collection(this.fireStore, `${this.basePath}`);
  }

  /**
   * Overide this on each class
   * @returns FirestoreDataConverter<any>
   */
  getConverter(): FirestoreDataConverter<any> {
    return {
      toFirestore: (companyModel: any) => {
        let docData = companyModel;

        docData.createdAt = companyModel.createdAt ? companyModel.createdAt : new Date().toISOString();
        docData.updatedAt = new Date().toISOString();

        // let docData = {
        //   uid: companyModel.uid,
        //   name: companyModel.name,
        //   description: companyModel.description,
        //   image_url: companyModel.image_url,
        //   createdAt: companyModel.createdAt ? companyModel.createdAt : new Date().toISOString(),
        //   updatedAt: new Date().toISOString(),
        //   ownerUserUid: companyModel.ownerUserUid
        // };
        docData = this.removeEmpty(docData);
        return docData;
      },
      fromFirestore: (snapshot: any, options: any) => {
        const data = snapshot.data(options);
        return data;
      },
    };
  }


  /**
   * Remove undefined and null keys from a object
   * @param obj
   * @returns
   */
  removeEmpty(obj: any) {
    const newObj: any = {};
    Object.keys(obj).forEach((key) => {


      if (obj[key] === Object(obj[key])) {
        newObj[key] = this.removeEmpty(obj[key]);
      } else if (obj[key] === undefined) { // replace undefined with empty strings
        newObj[key] = ""
      } else if (obj[key] !== undefined) {
        if (obj[key] !== null) {
          newObj[key] = obj[key];
        }
      }
    });
    return newObj;
  }

}
