import { Injectable, ErrorHandler } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {
  private appInsights!: ApplicationInsights;
  private appInsightsInstrumentationKey?: string;

  constructor(private http: HttpClient) {

    if(!environment.debug) {
      this.setUpAppInsights();
    }

    if (this.appInsightsInstrumentationKey) {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: this.appInsightsInstrumentationKey,
        }
      });
      this.appInsights.loadAppInsights();
    }
  }

  /**
   * Set up Application Insights
   * @returns void
   */
  setUpAppInsights() {
    this.getApplicationInsightsKey().subscribe((key: string) => {
      if(!environment.production) {
        // console.log('Application Insights Key:', key);
      }
      this.appInsightsInstrumentationKey = key;
    });
  }

  /**
   * Get Application Insights Key
   * @returns Application Insights Key
   */
  getApplicationInsightsKey(): Observable<string> {
    const url = environment.app_api_url + '/api/GetAppInsightsKey';
    return this.http.get<any>(url).pipe(
      map(response => {
        // Process your response here, adjust according to your actual response structure
        return response.access_key;
      }),
      catchError(this.handleApiError)
    );
  }


  // TODO Send this to a global service to track errors and send to a logging service
  private handleApiError(error: any): Observable<never> {
    // Handle errors in a user-friendly way
    console.error('An error occurred getting App Insights Key:', error.error.message);
    return throwError(() => new Error('An error occurred getting App Insights Key; please try again later.'));
  }


  /**
   * Handle Error
   * @param error Error
   * @returns void
   */
  handleError(error: any): void {
    console.error('An error occurred:', error);
    if(!environment.debug && this.appInsights) {
      this.appInsights.trackException({ exception: error });
    }
  }
}
